























































































































































































































































































































































































































































































































































































































































































































































































































































.templateList{
    .customBtn:nth-child(2){
        background-color: #5C6BE8;
        border: none;
        &:hover{
            background-color: #6875ee;
        }
    }
}
.docsDialog .el-form-item {
  margin-bottom: 20px;
}
